.map-container {
  h1{
    padding-left: 10%;
    font-size: 40px;
  }

  .product-map-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;

    .product-card-container {
      flex: 1;
      margin-right: 2rem;
    }

    .image-container {
      flex: 2;
      position: relative;

      .model-image {
        width: 100%;
        height: auto;
        /* Adjust height based on image aspect ratio */
        object-fit: cover;
        display: block;
      }

      .product-button {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        border: 2px solid #114084;
        cursor: pointer;
        transform: translate(-50%, -50%);
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.7);
        }
      }

      .hover-modal {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        transform: translateX(-50%);
        pointer-events: none;
        z-index: 10;
        animation: fadeIn 0.3s ease-in-out;

        p {
          margin: 0;
          font-size: 0.9rem;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .product-card-container {
        margin-bottom: 1.5rem;
        margin-right: 10%;
        margin-left: 0;
      }

      .image-container {
        .product-button {
          width: 24px;
          height: 24px;
        }

        .hover-modal {
          padding: 8px;

          p {
            font-size: 0.8rem;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .image-container {
        .product-button {
          width: 20px;
          height: 20px;
        }

        .hover-modal {
          padding: 6px;

          p {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}