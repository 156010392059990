.homepage {
    max-width: 100%;
    overflow-x: hidden;
    /* Ensure no horizontal overflow */
}

.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* Button stays at the bottom */
    z-index: -1;

    .hero-image {
        position: absolute;
        bottom: 0;
        right: -10%;
        width: 100%;
        height: 100%;
        max-width: fit-content;
        max-height: fit-content;
        object-fit: cover;
        /* Ensures the image covers both width and height, cropping if needed */
        object-position: center center;
        /* Center the image */
        z-index: -1;
        /* Ensure image stays behind the button */
        opacity: 0;
        /* Start with the image hidden */
        animation: fadeInRight 0.9s forwards;

        /* Apply the animation */
        @keyframes fadeInRight {
            0% {
                opacity: 0;
                transform: translateX(100%);
            }

            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }

    }

    .logo-image {
        position: absolute;
        top: 30%;
        left: 5%;
        width: 50%;
        height: 50%;
        max-width: fit-content;
        max-height: fit-content;
        object-fit: cover;
        /* Ensures the image covers both width and height, cropping if needed */
        object-position: center center;
        /* Center the image */
        z-index: -1;
        /* Ensure image stays behind the button */
        opacity: 0;
        /* Start with the image hidden */
        animation: fadeInRight 0.9s forwards;

        @media screen and (max-width: 768px) {
            opacity: 0 !important;
        }

        /* Apply the animation */
        @keyframes fadeInRight {
            0% {
                opacity: 0;
                transform: translateX(500%);
            }

            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    .order-button {
        margin-bottom: 20px;
        padding: 15px 30px;
        background-color: #114084;
        /* Adjust to match your color scheme */
        color: #f1f1f1;
        font-size: 1.5rem;
        text-decoration: none;
        border-radius: 5px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
            background-color: #3466aa;
            /* Hover color */
            transform: scale(1.05);
            /* Slight zoom on hover */
        }
    }

    @media (max-width: 768px) {
        height: 60vh;
        /* Reduce height for smaller screens */

        .order-button {
            font-size: 1.2rem;
            padding: 10px 20px;
        }
    }

    @media (max-width: 480px) {
        height: 50vh;

        .order-button {
            font-size: 1rem;
            padding: 8px 16px;
        }
    }
}

.product-section {
    padding: 2rem;

    .section-title {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1.5rem;

        @media (max-width: 768px) {
            font-size: 1.8rem;
        }

        @media (max-width: 480px) {
            font-size: 1.5rem;
        }
    }

    .slider-container {
        position: relative;
        display: flex;
        align-items: center;

        .product-slider {
            gap: 10px;
            padding-bottom: 20px;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }

        .slider-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #114084;
            color: white;
            border: none;
            padding: 10px;
            cursor: pointer;
            z-index: 10;
            font-size: 1.5rem;
            border-radius: 50%;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #3466aa;
            }

            &.left {
                left: 10px;
            }

            &.right {
                right: 10px;
            }

            @media (max-width: 768px) {
                padding: 8px;
                font-size: 1.2rem;
            }

            @media (max-width: 480px) {
                padding: 5px;
                font-size: 1rem;

            }
        }
    }

    .product-card {
        scroll-snap-align: start;

        @media (max-width: 768px) {
            min-width: 70%;

        }

        @media (max-width: 480px) {
            min-width: 100%;
        }
    }
}

.productmap {
    padding-top: 5%;
}

@media (max-width: 768px) {
    .product-section {
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .product-section {
        padding: 0.5rem;
    }
}