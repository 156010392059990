.about-us {
    .section {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh; /* Full screen height */
      padding: 2rem;
      scroll-snap-align: start; /* Snap each section */
      text-align: center;
      transition: opacity 0.3s ease;
      opacity: 0; /* Hide sections initially */
    }
  
    /* Navbar space */
    padding-top: 80px; /* Adjust this according to the height of your navbar */
  
    /* Make sections visible after page load */
    .section-visible {
      opacity: 1;
    }
  
    /* Individual section background colors or styles */
    .about-us-intro {
      background-color: #114084; /* Main theme color */
      color: #f1f1f1;
    }
  
    .about-us-history {
      background-color: #3466aa;
      color: #f1f1f1;
    }
  
    .about-us-team {
      background-color: #82b7dc;
      color: #1c1c1c;
    }
  
    .about-us-values {
      background-color: #f1f1f1;
      color: #1c1c1c;
    }
  
    /* Content styling */
    .content {
      max-width: 700px;
      margin: 0 auto;
  
      h1, h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
  
      p {
        font-size: 1.2rem;
        line-height: 1.6;
      }
    }
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  .about-us {
    scroll-snap-type: y mandatory; /* Snap scrolling vertically */
    overflow-y: scroll;
  }
  