.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2000;
  background-color: rgba(241, 241, 241, 0);
  /* Transparent by default */
  color: #082488;
  transition: background-color 0.6s ease-in-out, padding 0.3s ease, transform 0.5s ease;
  font-size: larger;
  max-width: 100%;
  overflow-x: hidden;
  /* Ensure no horizontal overflow */

  &.hidden {
    top: -100px;
  }

  &.visible {
    top: 0;
  }


  .hamburger-menu {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 0;
    margin-left: auto;
    margin-top: 1%;
    color: #1C1C1C !important;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    z-index: 1001;
    /* Ensure hamburger is on the right */

    .burger-line {
      width: 25px;
      height: 3px;
      background-color: #1C1C1C;
      /* Default color */
      margin: 4.5px 0 0 5px;
      transition: all 0.3s ease-in-out;
    }

    &.open .burger-line:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 5px);
    }

    &.open .burger-line:nth-child(2) {
      opacity: 0;
    }

    &.open .burger-line:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -5px);
    }

    @media (max-width: 768px) {
      display: flex;
      position: fixed;
      right: 1rem;
      top: 1rem;
      z-index: 1001;

      .burger-line {
        width: 25px;
        height: 3px;
        background-color: #f8f8f8;
        margin: 4px 0 0 0;
        transition: all 0.3s ease;
      }

      &.open .burger-line:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 5px);
      }

      &.open .burger-line:nth-child(2) {
        opacity: 0;
      }

      &.open .burger-line:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px);
      }
    }
  }

  &.scrolled,
  &.menu-open {
    background-color: rgba(241, 241, 241, 1);
    color: #1C1C1C;
    padding: 0.3rem 3rem 0 2rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    /* Ensure hamburger changes to black when scrolled or menu is open */
    .hamburger-menu .burger-line {
      background-color: #1C1C1C !important;
    }
  }

  .navbar-content {
    display: flex;
    justify-content: space-between;
    /* Ensures the space between logo and hamburger */
    align-items: center;
    width: 96%;
    position: relative;
  }

  .left-menu {
    display: flex;
    align-items: center;

    .logo {
      width: 80px;
      height: auto;
      margin-right: 2rem;
    }

    .nav-links {
      display: flex;
      list-style: none;
      .menu-item {
        position: relative;
        margin-left: 1.5rem;
        cursor: pointer;
        margin-bottom: 0;
        
        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            color: #82b7dc;
          }
        }

        .caret {
          margin-left: 5px;
          transition: transform 0.3s ease;
          display: inline-block;
        }

        .rotate {
          transform: rotate(180deg);
        }
      }
    }


    @media (max-width: 768px) {
      .nav-links {
        display: none;
        flex-direction: column;
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: rgba(241, 241, 241, 1);
        z-index: 1000;
        padding: 1rem 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      .nav-links.show {
        display: flex;
      }

      /* Ensure hamburger is displayed and aligned on the right */
      .hamburger-menu {
        display: flex;
        margin-left: auto;
        /* Ensures hamburger is aligned to the right */
        padding-right: 1rem;
        /* Extra padding to prevent overlap with screen edge */
      }

      /* Default hamburger color */
      .hamburger-menu .burger-line {
        background-color: #F1F1F1;
      }

      .nav-right {
        display: none;
      }
    }

    .mobile-menu {
      position: fixed;
      left: -400px;
      top: 0;
      width: 300px;
      height: 100%;
      background-color: #F1F1F1;
      transition: left 0.3s ease;
      z-index: 1000;
      padding: 2rem;
      overflow-y: auto;

      &.slide-in {
        left: 0;
      }

      .mobile-nav-links {
        list-style: none;
        padding: 0;
        margin-top: 30%;

        .menu-item {
          margin-bottom: 1.5rem;

          a {
            text-decoration: none;
            color: #1C1C1C;
            font-size: 1.5rem;
          }

          .caret {
            margin-left: 0.5rem;
            transition: transform 0.3s ease;
          }

          .rotate {
            transform: rotate(180deg);
          }

          .menu-item .caret {
            transition: transform 0.3s ease;
          }

          .menu-item.active .caret {
            transform: rotate(180deg); // Rotate the caret when submenu is open
          }

          .submenu {
            list-style: none;
            padding-left: 1.5rem;
            margin-top: 0.5rem;
            transition: max-height 0.3s ease, opacity 0.3s ease;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            background-color: #F1F1F1;
            z-index: 1000;

            &.show {
              max-height: 300px;
              /* Adjust this based on the submenu height */
              opacity: 1;
            }
          }

          .submenu-item {
            margin-bottom: 0.5rem;

            a {
              text-decoration: none;
              color: #3466aa;
              font-size: 1.2rem;
            }
          }

        }
      }
    }
  }

  .nav-right {
    padding-right: 5%;
    display: flex;
    list-style: none;
    align-items: center;

    li {
      margin-left: 1.5rem;

      a {
        color: inherit;
        text-decoration: none;
        font-size: 1.2rem;

        &:hover {
          color: #82b7dc;
        }
      }
    }
  }
}


/* Full-width dropdown menus */
.full-width-menu {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  background-color: rgba(241, 241, 241, 1);
  z-index: 50;
  /* Lower than the navbar */
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: visibility 0s 0.5s, opacity 1s ease, transform 0.5s ease;
  z-index: 1000;

  &.slideDown {
    visibility: visible !important;
    opacity: 1;
    animation: slideDown 0.5s ease forwards;
    z-index: 1000;

  }

  &.slideUp {
    animation: slideUp 0.5s ease forwards;
    opacity: 0;
    z-index: 50;
  }

  .menu-content {
    justify-content: flex-start;
    width: 100%;
    z-index: 50;
    display: flex;
    align-items: stretch;

    .menu-content-items{
      padding-left: 1%;
      width: 10%;

      li{
      cursor: pointer;

      }
    }

    ul {
      padding-right: 4%;
    }
  }

}

/* Keyframes for Slide Down */
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(8%);
  }

  100% {
    transform: translateY(0);
  }
}

/* Keyframes for Slide Up */
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}